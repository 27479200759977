import React, { useState } from "react";
import { useForm } from "react-hook-form";

const URL_POST_CONTACT_FORM = 'https://sms-api.wefast.app/api/notification';

const ContactForm = () => {
  const [data, setData] = useState();
  const { register, errors, handleSubmit } = useForm({
    mode: "onBlur",
  });
  const onSubmit = (data) => {
    setData({...data,...{isSuccess:0,error:'ĐANG GỬI YÊU CẦU....'}});
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
  };
  fetch(URL_POST_CONTACT_FORM, requestOptions)
      .then(response => {
        setData({error:'GỬI THÔNG TIN THÀNH CÔNG. CHÚNG TÔI SẼ LIÊN HỆ VỚI BẠN SỚM. XIN CẢM ƠN!', isSuccess: 1})
      })
      .catch(e=>{
        setData({error:'GỬI THÔNG TIN THẤT BẠI', isSuccess: 2})
        console.error(e)
      });
  };

  return (
    <div className="App">
      <form onSubmit={handleSubmit(onSubmit)}>
      <div>
          <label style={{ textAlign: "left", color:"white", fontWeight:"bold", fontSize:20}}>THÔNG TIN LIÊN HỆ - ĐĂNG KÝ SMS BRANDNAME</label>
        </div>
        <div>
          <label htmlFor="firstname">Họ*</label>
          <input
            name="firstname"
            placeholder="Họ"
            ref={register({ required: true, maxLength: 50 })}
          />
          {errors.firstname && (
            <p>Lỗi trong quá trình nhập liệu Họ: {errors.firstname.type}</p>
          )}
        </div>

        <div>
          <label htmlFor="lastname">Tên*</label>
          <input
            name="lastname"
            placeholder="Tên"
            ref={register({ required: true })}
          />
          {errors.lastname && (
            <p>Lỗi trong quá trình nhập liệu Tên: {errors.lastname.type}</p>
          )}
        </div>

        <div>
          <label htmlFor="email">
            Email*
          </label>
          <input name="email" placeholder="Email" ref={register({ required: true })} />
          {errors.email && (
            <p>Lỗi trong quá trình nhập liệu Email: {errors.email.type}</p>
          )}
        </div>
        <div>
          <label htmlFor="phone">
          Số Điện Thoại*
          </label>
          <input name="phone"  placeholder="Số Điện Thoại" ref={register({ required: true })} />
          {errors.phone && (
            <p>Lỗi trong quá trình nhập liệu Số Điện Thoại: {errors.phone.type}</p>
          )}
        </div>
        <div>
          <label htmlFor="message">Tin nhắn</label>
          <textarea name="message"  placeholder="Tin Nhắn" ref={register({ required: false })} />
        </div>
        {data && (
          <pre style={{ textAlign: "left", color: `${data.isSuccess === 1 ? 'green':(data.isSuccess === 0 ? 'white':'red')}`,fontSize:20 }}>
            {data.error}
          </pre>
        )}
        {!data || data.isSuccess !== 0 ? <input style={{cursor:'pointer'}} type="submit" value="GỬI"/> : <input style={{cursor:'pointer'}} disabled type="submit" value="GỬI"/>}
      </form>
    </div>
  );
};

export default ContactForm;
